//frontend/src/App.js
import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import SyncDashboard from './components/SyncDashboard';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
    background: {
      default: '#f5f5f5',
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <Container 
          maxWidth={false} // Permet de désactiver la largeur maximale
          sx={{
            width: '100%', // Utilise toute la largeur
            padding: 0, // Supprime les marges internes
            margin: 0, // Supprime les marges externes
            marginTop: '2rem', // Ajustez selon vos besoins
          }}
        >
          <SyncDashboard />
        </Container>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
