import React, { useState, useEffect, useCallback } from 'react';
import { FaPlay, FaStop, FaTrashAlt, FaDownload } from 'react-icons/fa';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001/api';

function SyncJobsList() {
    const [tabIndex, setTabIndex] = useState(0);
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [actionLoading, setActionLoading] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [jobsPerPage, setJobsPerPage] = useState(5);
    const [totalPages, setTotalPages] = useState(1);
    const [filters, setFilters] = useState({
        status: '',
        startDate: '',
        endDate: '',
        folder: '',
    });

    const fetchJobs = useCallback(async () => {
        setLoading(true);

        const type = tabIndex === 0 ? "immediate" : tabIndex === 1 ? "scheduled" : "history";

        const queryParams = new URLSearchParams({
            page: currentPage.toString(),
            limit: jobsPerPage.toString(),
            type,
        });

        if (filters.startDate) queryParams.append("startDate", filters.startDate);
        if (filters.endDate) queryParams.append("endDate", filters.endDate);
        if (filters.status) queryParams.append("status", filters.status);
        if (filters.folder) queryParams.append("folder", filters.folder);

        try {
            const response = await fetch(`${API_URL}/sync?${queryParams}`);
            if (!response.ok) throw new Error("Network response was not ok");
            const data = await response.json();
            setJobs(data.jobs || []);
            setTotalPages(data.totalPages || 1);
        } catch (error) {
            console.error("Error fetching jobs:", error);
        } finally {
            setLoading(false);
        }
    }, [tabIndex, currentPage, jobsPerPage, filters]);

    useEffect(() => {
        fetchJobs();
    }, [fetchJobs]);

    const handleTabChange = (newIndex) => {
        setTabIndex(newIndex);
        setCurrentPage(1);
        setFilters({
            status: '',
            startDate: '',
            endDate: '',
            folder: '',
        });
    };

    const handleFilterChange = (field, value) => {
        setFilters((prev) => ({
            ...prev,
            [field]: value,
        }));
        setCurrentPage(1);
    };

    const handleAction = async (jobId, action) => {
        setActionLoading(jobId);
        let method = 'PUT';
        let url = `${API_URL}/sync/${jobId}`;

        try {
            if (action === 'restart') {
                method = 'POST';
                url = `${API_URL}/sync/${jobId}/restart`;
            } else if (action === 'stop') {
                url = `${API_URL}/sync/${jobId}/stop`;
            } else if (action === 'delete') {
                method = 'DELETE';
            } else if (action === 'download') {
                window.open(`${API_URL}/sync/${jobId}/logs/download`, '_blank');
                setActionLoading(null);
                return;
            }

            const response = await fetch(url, { method });
            if (response.ok) {
                fetchJobs();
            } else {
                console.error(`Action "${action}" failed`);
            }
        } catch (error) {
            console.error(`Error performing action "${action}":`, error);
        } finally {
            setActionLoading(null);
        }
    };

    const getStatusBadge = (status) => {
        const statusStyles = {
            running: 'bg-blue-200 text-blue-800',
            pending: 'bg-yellow-200 text-yellow-800',
            completed: 'bg-green-200 text-green-800',
            failed: 'bg-red-200 text-red-800',
            stopped: 'bg-gray-200 text-gray-800',
        };
        return (
            <span className={`px-3 py-1 rounded-full text-sm font-semibold ${statusStyles[status]} shadow-sm`}>
                {status.toUpperCase()}
            </span>
        );
    };

    const formatDate = (date) => {
        if (!date) return 'N/A';
        const parsedDate = new Date(date);
        const day = parsedDate.getDate().toString().padStart(2, '0');
        const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
        const year = parsedDate.getFullYear();
        return `${day}/${month}/${year}`;
    };

    return (
        <div className="bg-gray-50 p-10 min-h-screen">
            <div className="container mx-auto bg-white rounded-xl shadow-lg p-8">
                <h2 className="text-4xl font-extrabold text-center text-blue-600 mb-8">
                    Gestion des Jobs de Synchronisation
                </h2>

                <div className="flex justify-center border-b mb-4">
                    <button
                        className={`py-3 px-6 ${
                            tabIndex === 0 ? 'border-b-4 border-blue-500 font-bold' : 'hover:text-blue-500'
                        }`}
                        onClick={() => handleTabChange(0)}
                    >
                        Jobs Immédiats
                    </button>
                    <button
                        className={`py-3 px-6 ${
                            tabIndex === 1 ? 'border-b-4 border-blue-500 font-bold' : 'hover:text-blue-500'
                        }`}
                        onClick={() => handleTabChange(1)}
                    >
                        Jobs Planifiés
                    </button>
                    <button
                        className={`py-3 px-6 ${
                            tabIndex === 2 ? 'border-b-4 border-blue-500 font-bold' : 'hover:text-blue-500'
                        }`}
                        onClick={() => handleTabChange(2)}
                    >
                        Historique des Jobs
                    </button>
                </div>

                <div className="mb-4">
                    <input
                        type="date"
                        className="border rounded p-2 mr-2"
                        value={filters.startDate}
                        onChange={(e) => handleFilterChange('startDate', e.target.value)}
                        placeholder="Date de début"
                    />
                    <input
                        type="date"
                        className="border rounded p-2 mr-2"
                        value={filters.endDate}
                        onChange={(e) => handleFilterChange('endDate', e.target.value)}
                        placeholder="Date de fin"
                    />
                    <select
                        className="border rounded p-2 mr-2"
                        value={filters.status}
                        onChange={(e) => handleFilterChange('status', e.target.value)}
                    >
                        <option value="">Tous les statuts</option>
                        <option value="pending">En attente</option>
                        <option value="running">En cours</option>
                        <option value="completed">Terminé</option>
                        <option value="failed">Échoué</option>
                        <option value="stopped">Arrêté</option>
                    </select>
                    <input
                        type="text"
                        className="border rounded p-2"
                        value={filters.folder}
                        onChange={(e) => handleFilterChange('folder', e.target.value)}
                        placeholder="Filtrer par dossier"
                    />
                </div>

                {loading ? (
                    <div className="flex justify-center py-10">
                        <div className="animate-spin h-12 w-12 border-4 border-blue-500 rounded-full"></div>
                    </div>
                ) : (
                    <table className="table-auto w-full border border-gray-200 rounded-lg shadow-md">
                        <thead className="bg-gray-100 text-gray-700">
                            <tr>
                                <th className="p-4 text-left">Date de création</th>
                                <th className="p-4 text-left">Date de début</th>
                                <th className="p-4 text-left">Date de fin</th>
                                {tabIndex === 1 && <th className="p-4 text-left">Prochaine exécution</th>}
                                <th className="p-4 text-left">Statut</th>
                                <th className="p-4 text-left">Dossiers</th>
                                <th className="p-4 text-left">Progression</th>
                                <th className="p-4 text-left">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            {jobs.map((job) => (
                                <tr key={job._id} className="hover:bg-blue-50 transition duration-200">
                                    <td className="p-4">{formatDate(job.createdAt)}</td>
                                    <td className="p-4">{formatDate(job.startDate)}</td>
                                    <td className="p-4">{formatDate(job.endDate)}</td>
                                    {tabIndex === 1 && <td className="p-4">{formatDate(job.nextExecutionDate)}</td>}
                                    <td className="p-4">{getStatusBadge(job.status)}</td>
                                    <td className="p-4 truncate max-w-sm">{job.folders.join(', ')}</td>
                                    <td className="p-4">
                                        <div className="relative w-full h-4 bg-gray-200 rounded-full">
                                            <div
                                                className="absolute left-0 top-0 h-4 bg-blue-500 rounded-full"
                                                style={{
                                                    width: `${(job.stats.processedFiles / (job.stats.totalFiles || 1)) * 100}%`,
                                                }}
                                            ></div>
                                        </div>
                                        <span className="text-sm text-gray-600 mt-1">
                                            {job.stats.processedFiles}/{job.stats.totalFiles || 1} fichiers
                                        </span>
                                    </td>
                                    <td className="p-4 flex flex-wrap gap-2">
                                        <button
                                            className={`flex items-center bg-red-500 hover:bg-red-600 text-white px-3 py-2 rounded-md shadow-md ${
                                                actionLoading === job._id ? 'opacity-50 cursor-not-allowed' : ''
                                            }`}
                                            onClick={() => handleAction(job._id, 'stop')}
                                            disabled={actionLoading === job._id}
                                        >
                                            <FaStop className="mr-2" /> Stop
                                        </button>
                                        <button
                                            className={`flex items-center bg-green-500 hover:bg-green-600 text-white px-3 py-2 rounded-md shadow-md ${
                                                actionLoading === job._id ? 'opacity-50 cursor-not-allowed' : ''
                                            }`}
                                            onClick={() => handleAction(job._id, 'restart')}
                                            disabled={actionLoading === job._id}
                                        >
                                            <FaPlay className="mr-2" /> Relancer
                                        </button>
                                        <button
                                            className={`flex items-center bg-yellow-500 hover:bg-yellow-600 text-white px-3 py-2 rounded-md shadow-md ${
                                                actionLoading === job._id ? 'opacity-50 cursor-not-allowed' : ''
                                            }`}
                                            onClick={() => handleAction(job._id, 'download')}
                                            disabled={actionLoading === job._id}
                                        >
                                            <FaDownload className="mr-2" /> Télécharger
                                        </button>
                                        <button
                                            className={`flex items-center bg-red-700 hover:bg-red-800 text-white px-3 py-2 rounded-md shadow-md ${
                                                actionLoading === job._id ? 'opacity-50 cursor-not-allowed' : ''
                                            }`}
                                            onClick={() => handleAction(job._id, 'delete')}
                                            disabled={actionLoading === job._id}
                                        >
                                            <FaTrashAlt className="mr-2" /> Supprimer
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}

                <div className="flex justify-between items-center mt-6">
                    <select
                        value={jobsPerPage}
                        onChange={(e) => setJobsPerPage(Number(e.target.value))}
                        className="border border-gray-300 rounded p-2 shadow-sm"
                    >
                        {[5, 10, 15, 20].map((size) => (
                            <option key={size} value={size}>
                                {size} par page
                            </option>
                        ))}
                    </select>
                    <div className="flex gap-2">
                        <button
                            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                            disabled={currentPage === 1}
                            className="px-4 py-2 border rounded-md hover:bg-gray-100 disabled:opacity-50"
                        >
                            Précédent
                        </button>
                        <span className="px-4 py-2">
                            Page {currentPage} sur {totalPages}
                        </span>
                        <button
                            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                            disabled={currentPage === totalPages}
                            className="px-4 py-2 border rounded-md hover:bg-gray-100 disabled:opacity-50"
                        >
                            Suivant
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SyncJobsList;
